var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { LazyImage, Styler, TypographyBody } from '@honeycomb/ui-core';
import { useGlobalContext } from '@honeycomb/data';
import { interjectResourceString } from '../../utils';
export function TripadvisorRating(_a) {
    var { tripadvisorFeedback, onClick } = _a, other = __rest(_a, ["tripadvisorFeedback", "onClick"]);
    const { resourceStrings: { HC_TRIP_ADVISOR_RATING_TOOLTIP, REVIEW_COUNT_PLURAL, REVIEW_COUNT_SINGULAR }, } = useGlobalContext();
    const { rating, numberOfReviews, ratingImageUrl } = tripadvisorFeedback;
    if (!rating || !numberOfReviews)
        return null;
    return (React.createElement(Styler, Object.assign({ button: Boolean(onClick), display: "inline-flex", alignItems: "center", zIndex: 1, pos: "relative", onClick: (event) => {
            onClick === null || onClick === void 0 ? void 0 : onClick(event);
        } }, other),
        ratingImageUrl && (
        // The negative margin is used to offset the padding in the image
        React.createElement(Styler, { ml: "-8px", display: "inline-flex" },
            React.createElement(LazyImage, { alt: "Rating", height: "auto", src: ratingImageUrl, title: HC_TRIP_ADVISOR_RATING_TOOLTIP, "data-id": "tripadvisor-rating" }))),
        React.createElement(TypographyBody, { component: "div", underlined: Boolean(onClick), size: "xs", weight: "semiBold" }, numberOfReviews === 1
            ? REVIEW_COUNT_SINGULAR
            : interjectResourceString(REVIEW_COUNT_PLURAL, numberOfReviews))));
}
